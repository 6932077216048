import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import avcfullImg from "../../images/projectsfull/avcfull.jpg";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>AVC Gemino</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="AVC Gemino"
              projectfulldescription="AVC Gemino’s new website aimed to spotlight their products and references. For this project I created a suitable design and developed the front-end."
              projectclient="AVC Gemino"
              projectrole="Web Design & Front-end Development"
              projectdate="2016"
              projectfullimg={<div className="project--img--inner"><img data-src={avcfullImg} className="project--img lazyload" alt="AVC Gemino" /></div>}
              projectnextlink="/projects/bofisc"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;